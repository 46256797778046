import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {useEffect, useState} from 'react'
import {getPaymentBudgetAmount, getPaymentId, postPaymentCredit} from '../../services/Api'
import {formatReal} from '../../utils'
import {TypePayment} from './components/payment/TypePayment'
import {useParams} from 'react-router-dom'
import {TermsCredit} from './components/pdf/Document'
import {useIntl} from 'react-intl'

export const BuyCredit = () => {
  const intl = useIntl()
  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({id: obj}, varInfo)
    }

    return intl.formatMessage({id: obj})
  }
  const {id, amount} = useParams<any>()
  const [dataId, setDataId] = useState<any>(id ? id : '')
  const [loading, setLoading] = useState(false)
  const [typeSelect, setTypeSelect] = useState<any>({image: true, express: false})
  const [amountCount, setAmountCount] = useState(1)
  const [valuePayment, setValuePayment] = useState('R$ 169,00')
  const [payment, setPayment] = useState<any>({})
  const [typeScreen, setTypeScreen] = useState(1)

  const changevaluePayment = (value: any) => {
    setLoading(true)

    getPaymentBudgetAmount(typeSelect?.express, value).then((data) => {
      setAmountCount(value)
      setValuePayment(formatReal(data?.price))
      setLoading(false)
    })
  }

  const currentSchema = Yup.object().shape({
    credit: Yup.number()
      .min(1, translateObj('VALID.MIN.CARACTER', {length: 1}))
      .max(999, translateObj('VALID.MAX.CARACTER', {length: 999}))
      .required(translateObj('VALID.REQUIRED')),
    accept_terms: Yup.mixed().required(translateObj('VALID.TERMS')),
  })

  const submitStep = (values: any) => {
    setLoading(true)

    postPaymentCredit({
      amount: Number(values.credit),
      cupon_name: values.cupon_name,
      is_celer_express: typeSelect?.express,
    }).then((data) => {
      setLoading(false)

      if (data) {
        setDataId(data?.id)
        setPayment(data)
        setValuePayment(formatReal(data?.price))
      }

      return false
    })
  }

  const selectPurchaseType = (type: any) => {
    let typeSelected: any = {image: false, express: false}
    typeSelected[type] = true

    let count = 1
    let value = ''
    if (typeSelected.image) {
      value = 'R$ 169,00'
    } else {
      count = 30
      value = 'R$ 69,90'
    }

    setAmountCount(count)
    setValuePayment(value)
    setTypeSelect(typeSelected)
  }

  const confirmTypeSelect = () => {
    changevaluePayment(amountCount)
    setTypeScreen(2)
  }

  useEffect(() => {
    if (dataId) {
      setLoading(true)
      getPaymentId(dataId).then((data) => {
        setLoading(false)
        setDataId(data?.id)
        setPayment(data)
        selectPurchaseType(data?.is_express ? 'express' : 'image')
        setValuePayment(formatReal(data?.price))
      })
    } else if (amount) {
      changevaluePayment(amount)
    }
  }, [amount])

  return (
    <div>
      {dataId === '' ? (
        <div>
          <h1 className='mb-10'>{translateObj('MESSAGE.BUYCREDITANDIMAGE')}</h1>
          <div className='card'>
            <div className='card-body'>
              {typeScreen === 1 && (
                <div className='text-center'>
                  <h3>{translateObj('MESSAGE.CHOOSETYPE')}</h3>
                  <p className='mt-5 mb-10'>
                    {/* {translateObj('MESSAGE.CHOOSETYPEBUY.DESCRIPTION')} */}
                    Faça a escolha entre Celer Premium e Celer para prosseguir com sua compra.
                  </p>

                  <div className='d-flex gap-10 justify-content-center content-card-buy-item'>
                    <div
                      className={`card-buy-item ${typeSelect?.image && 'active'}`}
                      onClick={() => selectPurchaseType('image')}
                    >
                      Celer Premium
                      <hr />
                      <span>
                        {translateObj('MESSAGE.BUYIMAGE.LINE1')}
                        <br />
                        {translateObj('MESSAGE.BUYIMAGE.LINE2')}
                      </span>
                    </div>
                    <div
                      className={`card-buy-item ${typeSelect?.express && 'active'}`}
                      onClick={() => selectPurchaseType('express')}
                    >
                      Celer
                      <hr />
                      <span>
                        30 imagens
                        <br />
                        R$ 69,90 / AVULSO
                      </span>
                    </div>
                  </div>

                  <button className='mt-10 btn btn-dark px-20' onClick={confirmTypeSelect}>
                    {translateObj('BTN.PROCCED')}
                  </button>
                </div>
              )}

              {typeScreen === 2 && (
                <Formik
                  validationSchema={currentSchema}
                  initialValues={
                    typeSelect.express
                      ? {
                          credit: 30,
                          accept_terms: '',
                          cupon_name: '',
                        }
                      : {
                          credit: 1,
                          accept_terms: '',
                          cupon_name: '',
                        }
                  }
                  onSubmit={submitStep}
                >
                  {({setFieldValue}) => (
                    <Form id='kt_buy_credit'>
                      <div className='row'>
                        <div className='col-6'>
                          <h5>{translateObj('MESSAGE.BUYDETAILS.TITLE')}</h5>

                          {typeSelect.image ? (
                            <div>
                              <ul className='mt-5'>
                                <li>{translateObj('MESSAGE.BUYDETAILS.TEXT1')}</li>
                                <li>{translateObj('MESSAGE.BUYDETAILS.TEXT2')}</li>
                                <li>{translateObj('MESSAGE.BUYDETAILS.TEXT3')}</li>
                                <li>{translateObj('MESSAGE.BUYDETAILS.TEXT4')}</li>
                                <li>* Acréscimo em imóveis mobiliados, consultar valores;</li>
                              </ul>

                              <div className='alert alert-warning mt-5'>
                                <div className='fw-bold mb-3'>Avisos:</div>
                                Funciona para qualquer ambiente e padrão de imóvel;
                                <br />
                                Pode ser usada em residenciais ou comerciais;
                                <br />
                                Permite briefing e direcionamento dos projetos;
                                <br />
                                Entrega em até 4 dias úteis;
                              </div>
                            </div>
                          ) : (
                            <div>
                              <ul className='mt-5'>
                                <li>Mínimo de 30 imagens;</li>
                                <li>Valor da imagem: R$ 69,90</li>
                              </ul>

                              <div className='alert alert-warning mt-5'>
                                <div className='fw-bold mb-3'>Avisos:</div>
                                Custo ainda mais acessivel;
                                <br />
                                Entrega em até 2 dias úteis;
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-6'>
                          <h5>{translateObj('MESSAGE.WANTQUANTITY')}:</h5>

                          <div className='fv-row mb-5 mt-5'>
                            <label className='fs-6 fw-bold form-label required'>
                              {translateObj('MESSAGE.QUANTITYOF') +
                                ' ' +
                                translateObj('MESSAGE.IMAGE').toLocaleLowerCase()}
                            </label>

                            <Field
                              type='number'
                              name='credit'
                              className='form-control form-control-lg form-control-solid'
                              onChange={(e: any) => {
                                changevaluePayment(e.target.value)
                                setFieldValue('credit', e.target.value)
                              }}
                              min={typeSelect.express && 30}
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='credit' />
                            </div>
                          </div>

                          <div className='text-primary mb-8'>
                            {translateObj('MESSAGE.YOUWILLPAY')} <b>{valuePayment}</b>{' '}
                            {translateObj('MESSAGE.FORIMAGES')}.
                          </div>

                          <div className='fv-row mb-7'>
                            <label className='fs-6 fw-bold form-label'>
                              {translateObj('MESSAGE.HAVECOUPON')}
                            </label>
                            <Field
                              type='text'
                              name='cupon_name'
                              className='form-control form-control-lg form-control-solid mb-5'
                              placeholder={translateObj('MESSAGE.ADDCOUPON')}
                            />
                          </div>

                          <div className='fv-row mb-7'>
                            <label className='fs-6 fw-bold form-label required'>
                              <Field
                                type='checkbox'
                                name='accept_terms'
                                className='form-check-input'
                                value='1'
                              />
                              <span className='mx-3'>
                                {translateObj('BTN.ACCEPT') + ' '}
                                <TermsCredit
                                  translateObj={translateObj}
                                  data={{amount: amountCount, price: valuePayment}}
                                />
                              </span>
                            </label>
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='accept_terms' />
                            </div>
                          </div>
                        </div>

                        <div className='mt-10'>
                          {loading ? (
                            <span
                              className='text-center indicator-progress'
                              style={{display: 'block'}}
                            >
                              {translateObj('MESSAGE.LOADING') + ' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            <div>
                              <button
                                type='submit'
                                className='btn btn-lg btn-dark text-white w-100'
                              >
                                <i className='fa fa-check pe-3' />
                                {translateObj('BTN.PROCCEDTOCHECKOUT')}
                              </button>
                            </div>
                          )}
                          <button
                            className='btn btn-default w-100'
                            onClick={() => setTypeScreen(1)}
                          >
                            {translateObj('BTN.BACK')}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h1 className='mb-10'>{translateObj('MESSAGE.PAYMENT')}</h1>
          <div className='card'>
            <div className='card-body'>
              <h5>{translateObj('MESSAGE.SELECTMETHODPAYMENT')}</h5>
              <div className='text-primary fs-5 p-3'>
                <span className='fs-4'>
                  {translateObj('MESSAGE.YOUWILLPAY')} <b>{valuePayment}</b>{' '}
                  {translateObj('MESSAGE.IN')} <b>{payment?.amount}</b>{' '}
                  {(payment?.amount === 1
                    ? translateObj('MESSAGE.IMAGE')
                    : translateObj('MESSAGE.IMAGES')
                  ).toLocaleLowerCase()}
                </span>
                {payment?.cupon_name && (
                  <div className='alert alert-warning mt-3'>
                    * {translateObj('MESSAGE.COUPONUSING')} "<b>{payment.cupon_name}</b>"{' '}
                    {translateObj('MESSAGE.GIVINGDISCOUNT')} <b>{formatReal(payment.discount)}</b>
                    <br />* {translateObj('MESSAGE.PRICEBEFORECOUPON')}{' '}
                    <b>{formatReal(payment.full_price)}</b>
                  </div>
                )}
              </div>

              <hr />

              <TypePayment translateObj={translateObj} credit={{dataId, valuePayment}} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
