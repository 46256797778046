/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import Step1 from './components/createProject/steps/Step1'
import Step2 from './components/createProject/steps/Step2'
import Step3 from './components/createProject/steps/Step3'
import { postProperty, postOrder, postOrderPhoto, putOrderSend, getPaymentCredit } from '../../services/Api'
import { ModalConfirm } from './components/ModalConfirm'
import { useIntl } from 'react-intl'
import TypeProject from './components/TypeProject'
import {KTSVG} from '../../../_metronic/helpers'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import Smartlook from 'smartlook-client'

export interface IValues {
  companyId?: string
  name: string
  category: string
  type: string
  product: string
  zip_code: number | string
  price: number | string
  decoration: string
  package: number | string
  photos: {
    type: string
    file: any
    sendFile: string
    description: string
    link?: string
    description_measures?: string
  }[]
  cardName: string
  cardNumber: string
  cardExpiryMonth: string
  cardExpiryYear: string
  cardCvv: string
}

function ScreenNewProject(props: { translateObj: any, typeSelect: any, user: any }) {
  const { translateObj, typeSelect, user } = props;

  const initialValues: IValues = (user?.type === 'Squad' || user?.type === 'Squadinator') ? {
    companyId: '',
    name: '',
    category: '',
    type: '',
    product: 'Economic',
    zip_code: '',
    price: '',
    decoration: 'Classic',
    package: '',
    photos: [{
      type: '',
      file: undefined,
      sendFile: '',
      description: '',
      link: '',
      description_measures: '',
    }],
    cardName: '',
    cardNumber: '',
    cardExpiryMonth: '',
    cardExpiryYear: '',
    cardCvv: '',
  } : {
    name: '',
    category: '',
    type: '',
    product: 'Economic',
    zip_code: '',
    price: '',
    decoration: 'Classic',
    package: '',
    photos: [{
      type: '',
      file: undefined,
      sendFile: '',
      description: '',
      link: '',
      description_measures: '',
    }],
    cardName: '',
    cardNumber: '',
    cardExpiryMonth: '',
    cardExpiryYear: '',
    cardCvv: '',
  }

  const registrationSchema = (user?.type === 'Squad' || user?.type === 'Squadinator') ? [Yup.object().shape({
    "companyId": Yup.string().required(translateObj("VALID.REQUIRED")),
    "name": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "category": Yup.string().required(translateObj("VALID.REQUIRED")),
    "type": Yup.string().required(translateObj("VALID.REQUIRED")),
    "product": Yup.string().required(translateObj("VALID.REQUIRED")),
    "zip_code": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(9, translateObj("VALID.MAX.CARACTER", { "length": 9 })),
    "price": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(13, translateObj("VALID.MAX.CARACTER", { "length": 13 }))
      .required(translateObj("VALID.REQUIRED")),
    "decoration": Yup.string().required(translateObj("VALID.REQUIRED")),
  }), Yup.object().shape({
    "photos": Yup.array()
      .of(
        Yup.object().shape({
          "type": Yup.string().required(translateObj("VALID.REQUIRED")),
          "sendFile": Yup.string().required(translateObj("VALID.REQUIRED")),
        })
      )
      .required(translateObj("VALID.AMBIENCE.REQUIRED"))
      .min(1, translateObj("VALID.MIN.AMBIENCE", { "length": 1 })),
  }),] : [Yup.object().shape({
    "name": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(50, translateObj("VALID.MAX.CARACTER", { "length": 50 }))
      .required(translateObj("VALID.REQUIRED")),
    "category": Yup.string().required(translateObj("VALID.REQUIRED")),
    "type": Yup.string().required(translateObj("VALID.REQUIRED")),
    "product": Yup.string().when('typeSelect.celer', {
      is: true,
      then: Yup.string().required(translateObj("VALID.REQUIRED")),
      otherwise: Yup.string().notRequired()
    }),
    "zip_code": Yup.string()
      .matches(/^\d{5}-?\d{3}$/, translateObj("VALID.ZIP_CODE.FORMAT"))
      .min(8, translateObj("VALID.MIN.CARACTER", { "length": 8 }))
      .max(9, translateObj("VALID.MAX.CARACTER", { "length": 9 })),
    "price": Yup.string()
      .min(3, translateObj("VALID.MIN.CARACTER", { "length": 3 }))
      .max(13, translateObj("VALID.MAX.CARACTER", { "length": 13 }))
      .required(translateObj("VALID.REQUIRED")),
    "decoration": Yup.string(),
  }), Yup.object().shape({
    "photos": Yup.array()
      .of(
        Yup.object().shape({
          "type": Yup.string().required(translateObj("VALID.REQUIRED")),
          "sendFile": Yup.string().required(translateObj("VALID.REQUIRED")),
        })
      )
      .required(translateObj("VALID.AMBIENCE.REQUIRED"))
      .min(1, translateObj("VALID.MIN.AMBIENCE", { "length": 1 })),
  }),]

  const [completed, setCompleted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalConfirm, setModalConfirm] = useState(false)
  const [validSendPhotos, setValidSendPhotos] = useState(false)
  const [file, setFile] = useState<any[]>([{}, {}, {}])
  const [propertyId, setPropertyId] = useState<any>('')
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(registrationSchema[0])
  const [order, setOrder] = useState<any>()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const sendOrder = async (data: any, orderId: any, stepper: any) => {
    await putOrderSend({
      id: orderId,
      name: data.name,
      property_id: propertyId,
      standard: data.product,
      status: 'Draft',
      package: data.package,
    }).then(() => {
      stepper.current.goNext()
    })
  }

  const saveProject = async (data: any) => {
    setLoading(true)

    const zip_code = data.zip_code
    const price = data.price

    data.zip_code = Number(zip_code.replace('-', ''))
    data.price = price.replaceAll('.', '').replace(',', '.')

    await postProperty(data).then(async (dataProperty) => {
      const propertyId = dataProperty?.id

      // Restaura mask
      data.zip_code = zip_code
      data.price = price

      if (propertyId) {
        setPropertyId(dataProperty.id)

        await postOrder({
          companyId: data?.companyId,
          is_celer_express: typeSelect?.express,
          name: data.name,
          property_id: propertyId,
          standard: data.product,
          decoration: data.decoration,
          status: 'Draft',
          package: data.package,
          last_order_id: completed ? order?.id : '',
        }).then(async (dataOrder) => {
          const orderId = dataOrder?.id

          if (!orderId) {
            alert(translateObj('MESSAGE.FAILURE'))
            window.location.reload()
          }

          setOrder({package: data.package, id: orderId, enviroments: data.photos})

          var isSendOrder = 0
          await data.photos.map(async (item: any, key: any) => {
            const form = new FormData()
            const countAmbience = key + 1
            const nameCategory = countAmbience + ' - ' + item.type

            form.append('companyId', data?.companyId)
            form.append('name', nameCategory)
            form.append('description', item.description)

            await file[key]?.forEach((itemFile: any) => {
              form.append('file', itemFile)
            })

            form.append('order_id', orderId)
            form.append('album_link', item.link)
            form.append('measurements', item.description_measures)

            await postOrderPhoto(form).then(async () => {
              if (!isSendOrder) {
                isSendOrder++
                await sendOrder(data, orderId, stepper)
                setLoading(false)
              }
            })
          })
        })
      }
    })
  }

  const submitStep = (values: any) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex === 1) {
      setCurrentSchema(registrationSchema[1])
      // eslint-disable-next-line no-restricted-globals
      scroll(0, 0)
    } else if (
      stepper.current.currentStepIndex === 2 &&
      !validSendPhotos &&
      values.photos.length < 3
    ) {
      setModalConfirm(true)
      return
    } else if (
      stepper.current.currentStepIndex === 2 ||
      (stepper.current.currentStepIndex === 3 && completed)
    ) {
      setCompleted(true)
      saveProject(values)
      return
    }

    stepper.current.goNext()
  }

  const getContentEdit = (item: any) => {
    item === 1 && setCurrentSchema(registrationSchema[0])
    item === 2 && setCurrentSchema(registrationSchema[1])
    if (item === 3 && !loading) {
      document.getElementById('buttonSubmit')?.click()
    }

    stepper.current?.goto(item)
    return
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div ref={stepperRef} className='stepper stepper-links' id='kt_create_project_stepper'>
      <div className='stepper-nav mb-5'>
        <div
          className={`stepper-item current ${completed && `cursor-pointer`}`}
          data-kt-stepper-element='nav'
          onClick={() => {
            completed && getContentEdit(1)
          }}
        >
          <h3 className='stepper-title'>{translateObj('MESSAGE.IMMOBILE')}</h3>
        </div>

        <div
          className={`stepper-item ${completed && `cursor-pointer`}`}
          data-kt-stepper-element='nav'
          onClick={() => {
            completed && getContentEdit(2)
          }}
        >
          <h3 className='stepper-title'>{translateObj('MESSAGE.PHOTOS')}</h3>
        </div>

        <div
          className={`stepper-item ${completed && `cursor-pointer`}`}
          data-kt-stepper-element='nav'
          onClick={() => {
            completed && getContentEdit(3)
          }}
        >
          <h3 className='stepper-title'>
            {loading && completed && (
              <span className='spinner-border spinner-border-sm align-middle me-2'></span>
            )}
            {translateObj('MESSAGE.CONFIRMATION')}
          </h3>
        </div>
      </div>

      <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitStep}>
        {({values, setFieldValue, setValues}) => (
          <Form className='mx-auto mw-600px w-100 pt-5 pb-5' id='kt_create_project_form'>
            <div className='current' data-kt-stepper-element='content'>
              <Step1
                translateObj={translateObj}
                setFieldValue={setFieldValue}
                loading={loading}
                typeSelect={typeSelect}
                user={user}
              />
            </div>

            <div data-kt-stepper-element='content'>
              <ModalConfirm
                translateObj={translateObj}
                show={modalConfirm}
                setShow={setModalConfirm}
                setValidSendPhotos={setValidSendPhotos}
              />
              <Step2
                translateObj={translateObj}
                file={file}
                setFile={setFile}
                setFieldValue={setFieldValue}
                setValues={setValues}
                loading={loading}
                values={values}
                typeSelect={typeSelect}
              />
            </div>

            <div data-kt-stepper-element='content'>
              <Step3
                translateObj={translateObj}
                order={order}
                values={values}
                loading={loading}
                typeSelect={typeSelect}
                user={user}
              />
            </div>

            <button type='submit' id='buttonSubmit' style={{display: 'none'}} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default function CreateProject() {
  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any;
  const [typeScreen, setTypeScreen] = useState(1);
  const [typeSelect, setTypeSelect] = useState<any>({ celer: true, express: false, celerAI: false });
  const [hasPremiumCredits, setHasPremiumCredits] = useState<boolean>(false)
  const [hasCredits, setHasCredits] = useState<boolean>(false)
  const isTeam = user?.type === 'Squad' || user?.type === 'Squadinator' || user?.type === 'Operator'

  const intl = useIntl()
  const translateObj = (obj: any, varInfo?: any) => {
    if (varInfo) {
      return intl.formatMessage({id: obj}, varInfo)
    }

    return intl.formatMessage({id: obj})
  }

  const selectPurchaseType = (type: any) => {
    let typeSelected: any = {celer: false, express: false, celerAI: false}
    typeSelected[type] = true

    setTypeSelect(typeSelected)
  }

  const confirmTypeSelect = () => {
    setTypeScreen(2)
  }

  useEffect(() => {
    Smartlook.init('6f60e2d06792b219ec4044d8cd8e008a02afdece');

    getPaymentCredit().then(data => {
      if (data?.celer_express > 0) {
        setHasCredits(true)
      }

      if (data?.image > 0) {
        setHasPremiumCredits(true)
      }
    })
  }, [])

  useEffect(() => {
    if (hasPremiumCredits) {
      selectPurchaseType('celer')
    } else {
      selectPurchaseType('express')
    }
  }, [hasPremiumCredits])

  return (
    <div>
      <div className='mb-10'>
        <h1>{translateObj('MESSAGE.NEWPROJECT')}</h1>
        <p>
          {translateObj('MESSAGE.NEWPROJECT.DESCRIPTION', {
            type: typeSelect.celer
              ? translateObj('MESSAGE.PRODUCT.CELER')
              : translateObj('MESSAGE.PRODUCT.EXPRESS'),
          })}
        </p>
      </div>
      <div className='card'>
        <div className='card-body'>
          {typeScreen === 2 && (
            <a href='#' onClick={() => setTypeScreen(1)}>
              <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-4 me-1' />
              {translateObj('BTN.BACK')}
            </a>
          )}
          {typeScreen === 1 && (
            <TypeProject
              translateObj={translateObj}
              typeSelect={typeSelect}
              selectPurchaseType={selectPurchaseType}
              confirmTypeSelect={confirmTypeSelect}
              hasPremiumCredits={hasPremiumCredits}
              hasCredits={hasCredits}
              isTeam={isTeam}
            />
          )}
          {typeScreen === 2 && (
            <ScreenNewProject translateObj={translateObj} typeSelect={typeSelect} user={user} />
          )}
        </div>
      </div>
    </div>
  )
}
