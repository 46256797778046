import {Modal} from 'react-bootstrap-v5'
import {Dispatch, SetStateAction, useState} from 'react'
import {formatReal} from '../../../../utils'
import {TypePayment} from '../../../credit/components/payment/TypePayment'
import {postPaymentCredit} from '../../../../services/Api'
import {TermsCredit} from '../../../credit/components/pdf/Document'
import toast from 'react-hot-toast'

enum Type {
  loose = 1,
}

export function ModalUseCredit(props: {
  translateObj: any
  show: any
  setShow: Dispatch<SetStateAction<boolean>>
  data: any
  typeSelectOrder: any
}) {
  const {translateObj, show, setShow, data, typeSelectOrder} = props
  const length = data?.enviroments?.length

  const [loading, setLoading] = useState(false)
  const [paymentData, setPaymentData] = useState<any>({})
  const [coupon, setCoupon] = useState('')
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [typeSelected, setTypeSelected] = useState(1)

  const handleClose = () => {
    setShow(!show)
  }

  const verifyAcceptTerm = (elem: any) => {
    setAcceptTerms(elem.target.checked)
  }

  const sendPayment = async () => {
    if (!acceptTerms) {
      toast.error(translateObj('MESSAGE.TERMS'), {position: 'bottom-right'})
    } else if (!loading) {
      setLoading(true)

      let param = {}
      if (typeSelected === Type.loose) {
        param = {
          cupon_name: coupon,
          amount: 1,
          is_loose: true,
          order_id: data?.id,
          is_celer_express: typeSelectOrder.celerAI,
        }
      } else {
        param = {cupon_name: coupon, amount: 1, is_celer_express: typeSelectOrder.celerAI}
      }

      await postPaymentCredit(param).then((dataResponse) => {
        setLoading(false)

        if (dataResponse) {
          setPaymentData({creditId: dataResponse?.id, valueCredit: formatReal(dataResponse?.price)})
        }

        return false
      })
    }
  }

  return (
    <Modal size={'lg'} show={show} onHide={handleClose}>
      <Modal.Body>
        <div>
          <button
            type='button'
            className='btn p-0 m-0 position-absolute'
            style={{top: '3%', right: '3%', padding: 5}}
            onClick={handleClose}
          >
            <i className='fa fa-times'></i>
          </button>

          <>
            {paymentData?.creditId ? (
              <>
                <h3 className='text-primary'>{translateObj('MESSAGE.MAKEPAYMENT')}</h3>
                <TypePayment translateObj={translateObj} credit={paymentData} />
              </>
            ) : (
              <div className='p-5'>
                <h3 className='text-primary'>{translateObj('MESSAGE.PURCHASENOTICE')}</h3>
                {!loading ? (
                  <div>
                    <b>{translateObj('MESSAGE.SELECTMETHODPAYMENT')}</b>
                    <div className='d-flex gap-10 mt-3 mb-5'>
                      <label className='d-flex gap-5 cursor-pointer alert alert-secondary'>
                        <input
                          type='radio'
                          name='type'
                          checked={typeSelected === 1}
                          value={1}
                          onClick={() => setTypeSelected(1)}
                        />
                        <div>
                          <h5>{translateObj('MESSAGE.BUYLOOSE')}</h5>
                          <div>
                            {translateObj('MESSAGE.YOUWILLPAY') +
                              ' ' +
                              formatReal(169 * length) +
                              ' ' +
                              translateObj('MESSAGE.IN') +
                              ' ' +
                              (length === 1
                                ? '1 ' + translateObj('MESSAGE.IMAGE')
                                : `${length} ` + translateObj('MESSAGE.IMAGES'))}
                          </div>
                        </div>
                      </label>
                      <label className='d-flex gap-5 cursor-pointer alert alert-secondary'>
                        <input
                          type='radio'
                          name='type'
                          checked={typeSelected === 2}
                          value={2}
                          onClick={() => setTypeSelected(2)}
                        />
                        <div>
                          <h5>{translateObj('MESSAGE.BUYLOOSE')}</h5>
                          <div>
                            {translateObj('MESSAGE.YOUWILLPAY') +
                              ' ' +
                              formatReal(69.9 * length) +
                              ' ' +
                              translateObj('MESSAGE.IN') +
                              ' ' +
                              (length === 1
                                ? '1 ' + translateObj('MESSAGE.IMAGE')
                                : `${length} ` + translateObj('MESSAGE.IMAGES'))}
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                ) : (
                  <>
                    {translateObj('MESSAGE.LOADING') + ' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </>
                )}
                <div className='mb-5'>
                  <label className='fw-bold'>{translateObj('MESSAGE.HAVECOUPON')}</label>
                  <div>
                    <small>{translateObj('MESSAGE.HAVECOUPON.DESCRIPTION')}</small>
                    <input
                      type='text'
                      name='cupon_name'
                      className='form-control mt-2 form-control-solid'
                      placeholder='Adicione o seu cupom'
                      style={{width: 200}}
                      onKeyUp={(e: any) => setCoupon(e.target.value)}
                    />
                  </div>
                </div>
                <label className='fs-6 fw-bold form-label required'>
                  <input
                    type='checkbox'
                    name='accept_terms'
                    className='form-check-input'
                    value='1'
                    onChange={(e) => verifyAcceptTerm(e)}
                  />
                  <span className='mx-3'>
                    {translateObj('BTN.ACCEPT') + ' '}
                    <TermsCredit
                      translateObj={translateObj}
                      data={{
                        amount: typeSelected === Type.loose ? length : 1,
                        price:
                          typeSelected === Type.loose ? formatReal(169 * length) : formatReal(449),
                        type: typeSelected,
                      }}
                    />
                  </span>
                </label>
                <hr className='my-6' />
                <div>
                  <p>
                    {translateObj('MESSAGE.YOUMAKINGPURCHASE') +
                      ' ' +
                      (typeSelected === Type.loose && translateObj('MESSAGE.SINGLE'))}
                    .
                  </p>
                  <button type='button' className='btn btn-primary w-100' onClick={sendPayment}>
                    {translateObj('BTN.PROCCEDTOCHECKOUT')}
                  </button>
                </div>
              </div>
            )}
          </>
        </div>
      </Modal.Body>
    </Modal>
  )
}
